var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ma-1"},[(!_vm.dailyReport.hasOwnProperty('ordinaryHours'))?_c('div',{staticStyle:{"flex":"100%","text-align":"center"}},[_c('span',[_vm._v("-")])]):_c('v-row',{staticClass:"ma-0",attrs:{"justify":"center"}},[_c('span',[_vm._v(" "+_vm._s(_vm.dailyReport.ordinaryHours)+" ")]),(_vm.totalVacancyTime > 0)?_c('span',{staticClass:"mx-1"},[_vm._v("+")]):_vm._e(),(_vm.totalVacancyTime > 0)?_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","label":"","color":"#E0F6D6"}},'v-chip',attrs,false),on),[_c('span',{staticStyle:{"color":"#262626"}},[_vm._v(_vm._s(_vm.totalVacancyTime))])])]}}],null,false,3458965584)},[_c('span',[_vm._v(_vm._s(_vm.translations.vacancyTime))])]):_vm._e(),(_vm.dailyReport.extraOrdinaryHours > 0)?_c('span',{staticClass:"mx-1"},[_vm._v("+")]):_vm._e(),(_vm.dailyReport.extraOrdinaryHours > 0)?_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({attrs:{"small":"","label":"","color":"#FFFCDE"}},'v-chip',attrs,false),on),[_c('span',{staticStyle:{"color":"#262626"}},[_vm._v(_vm._s(_vm.dailyReport.extraOrdinaryHours))])])]}}],null,false,1962106705)},[_c('span',[_vm._v(_vm._s(_vm.translations.extraOrdinaryHours))])]):_vm._e()],1),(_vm.hasVan)?_c('v-row',{staticClass:"ma-0 mt-2",attrs:{"justify":"center"}},[_c('v-tooltip',{attrs:{"top":"","color":"black"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"color":"main","small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-van-utility")])]}}],null,false,36210847)},[_c('span',[_vm._v(_vm._s(_vm.translations.usedVan))])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }