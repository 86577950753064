<template>
  <div class="ma-1">
    <div v-if="!dailyReport.hasOwnProperty('ordinaryHours')" style="flex: 100%; text-align: center;">
      <span>-</span>
    </div>

    <v-row v-else class="ma-0" justify="center">
      <span>
        {{ dailyReport.ordinaryHours }}
      </span>

      <span v-if="totalVacancyTime > 0" class="mx-1">+</span>
      <v-tooltip v-if="totalVacancyTime > 0" top color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-chip small label v-bind="attrs" v-on="on" color="#E0F6D6">
            <span style="color :#262626;">{{ totalVacancyTime }}</span>
          </v-chip>
        </template>
        <span>{{ translations.vacancyTime }}</span>
      </v-tooltip>

      <span v-if="dailyReport.extraOrdinaryHours > 0" class="mx-1">+</span>
      <v-tooltip v-if="dailyReport.extraOrdinaryHours > 0" top color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-chip small label v-bind="attrs" v-on="on" color="#FFFCDE">
            <span style="color :#262626;">{{ dailyReport.extraOrdinaryHours }}</span>
          </v-chip>
        </template>
        <span>{{ translations.extraOrdinaryHours }}</span>
      </v-tooltip>
    </v-row>

    <!-- v-if="hasVan || hasAllowance" -->
    <v-row v-if="hasVan" class="ma-0 mt-2" justify="center">
      <!-- v-if="hasVan" -->
      <v-tooltip top color="black">
        <template v-slot:activator="{ on, attrs }">
          <v-icon color="main" v-on="on" v-bind="attrs" class="mr-2" small>mdi-van-utility</v-icon>
        </template>
        <span>{{ translations.usedVan }}</span>
      </v-tooltip>
    </v-row>
  </div>
</template>

<script>
import { translations } from "@/utils/common";

export default {
  props: {
    dailyReport: {
      type: Object,
      required: true
    },
    isToday: {
      type: Boolean,
      default: () => false
    }
  },

  computed: {
    translations: () => translations,

    hasVan() {
      if (!this.dailyReport?.van) return false;
      return true;
    },

    totalVacancyTime() {
      const activitiesHours = this.dailyReport.activitiesHours;
      if (activitiesHours && Object.keys(activitiesHours).length > 0) return activitiesHours[Object.keys(activitiesHours)[0]];
      else return 0;
    }
  }
};
</script>

<style></style>
