<template>
  <div class="d-flex align-center">
    <v-btn @click="$emit('previous')" depressed fab color="main" x-small :disabled="disablePreviousBtn">
      <v-icon color="white">mdi-arrow-left-bold</v-icon>
    </v-btn>

    <v-menu v-model="menu" bottom left offset-y :close-on-content-click="false">
      <template v-slot:activator="{ on }">
        <v-btn x-large v-on="on" color="main" text class="ma-1" :style="type === 'monthly' ? 'width: 190px' : 'width: 260px'">
          <v-icon size="25" color="main" class="mr-1">mdi-calendar-month</v-icon>
          {{ displayItem.text }}
        </v-btn>
      </template>

      <div class="menu-container">
        <div>
          <h4 class="main--text">{{ translations.selectReportType }}:</h4>
          <v-checkbox v-for="item in types" @change="typeHandler" :key="item.value" v-model="type" :label="item.text" :value="item.value" :dense="true" :hide-details="true" class="mt-0 ml-2"></v-checkbox>
        </div>

        <div v-if="type === 'weekly'" class="mt-4 main--text">
          <h4>{{ translations.selectStartDate }}</h4>
          <v-date-picker v-model="picker" @change="onDateChange" :max="maxDateWeekly" locale="it"></v-date-picker>
        </div>

        <div v-else-if="type === 'custom'" class="mt-4 main--text">
          <h4>{{ translations.customDates }}</h4>

          <v-row class="ma-0 mt-4" align="center">
            <v-col class="pa-0" cols="2">
              <b class="mr-2">{{ translations.from }}</b>
            </v-col>
            <v-col class="pa-0">
              <date-picker @selected-date="(value) => setDate(value, 'dateFrom')"
                           :key="`a-${counter}`"
                           :applyMinDate="false"
                           :maxDateProp="today"/>
            </v-col>
          </v-row>

          <v-row class="ma-0 mt-4" align="center">
            <v-col class="pa-0" cols="2">
              <b class="mr-2">{{ translations.to }}</b>
            </v-col>
            <v-col class="pa-0">
              <date-picker @selected-date="(value) => setDate(value, 'dateTo')"
                           :key="`b-${counter}`"
                           :applyMinDate="false"
                           :minDateProp="dateFrom"/>
            </v-col>
          </v-row>

          <v-row class="ma-0 mt-2" justify="end">
            <v-btn @click="getReportHandler" color="main" class="white--text" small :disabled="disableSearchBtn">
              {{ translations.getReport }}
            </v-btn>
          </v-row>
        </div>
      </div>
    </v-menu>

    <v-btn @click="$emit('next')" fab color="main" depressed x-small :disabled="disableNextBtn">
      <v-icon color="white">mdi-arrow-right-bold</v-icon>
    </v-btn>
  </div>
</template>


<script>
import { translations } from '@/utils/common'
import DatePicker from '@/components/DatePicker.vue'

export default {
  components: {
    DatePicker
  },
  props: {
    displayType: {
      type: String,
      required: true
    },
    displayItem: {
      type: Object,
      default: () => {
      }
    },
    todayDate: {
      type: Number
    },
    todayMonth: {
      type: Number
    },
    todayYear: {
      type: Number
    },
    maxDateWeekly: {
      type: String,
      default: ''
    },
    headersHaveMaxDate: {
      type: Boolean,
      default: false
    },
    today: {
      type: String,
      default: ''
    }
  },

  data: () => ({
    picker: null,
    type: null,
    menu: false,
    counter: 0,
    dateFrom: '',
    dateTo: ''
  }),

  created() {
    this.type = this.displayType
  },

  methods: {
    onDateChange(date) {
      this.$emit('set-weekly', date)
      this.menu = false
    },

    typeHandler(type) {
      if (type === 'monthly') {
        this.$emit('set-monthly')
        this.menu = false
      }
    },

    setDate(date, type) {
      this[type] = date.simple
    },

    getReportHandler() {
      this.$emit('set-custom', { from: this.dateFrom, to: this.dateTo })
      this.menu = false
    }
  },

  computed: {
    translations: () => translations,

    disableNextBtn() {
      if (this.displayType === 'monthly') {
        return false
      } else if (this.displayType === 'weekly') {
        return this.headersHaveMaxDate
      } else if (this.displayType === 'custom') {
        return true
      }
      return false
    },

    disablePreviousBtn() {
      if (this.displayType === 'custom') return true
      return false
    },

    disableSearchBtn() {
      if (!this.dateFrom || !this.dateTo) return true
      return false
    },

    types() {
      const types = [
        { value: 'monthly', text: 'Mensile' },
        { value: 'weekly', text: 'Settimanalmente' },
        { value: 'custom', text: 'Personalizzata' }
      ]

      return types
    }
  },

  watch: {
    menu(newVal) {
      if (!newVal) this.counter++
    }
  }
}
</script>


<style scoped>
.menu-container {
  background-color: #fff;
  z-index: 3;
  padding: 16px;
  min-width: 300px;
}
</style>
